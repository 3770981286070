<template>
  <div
    v-click-outside="handleCloseSidebar"
    :class="
      openSidebar
        ? '-translate-x-0 shadow-xl lg:shadow-none'
        : '-translate-x-full lg:-translate-x-0'
    "
    class="fixed bottom-0 left-0 top-0 z-[60] flex min-h-screen w-[270px] flex-col overflow-auto border-r border-solid border-[#E2E8F0] bg-[#F8F9FA] duration-200 lg:z-20"
  >
    <div class="px-5">
      <div class="mt-5 block lg:hidden">
        <Button
          variant="outline"
          size="icon"
          class="bg-[#F8F9FA]"
          @click="handleCloseSidebar"
        >
          <XIcon class="h-6 w-6 text-primary" />
        </Button>
      </div>
      <div class="hidden h-[80px] items-center border-b lg:flex">
        <the-icon icon-name="logo" style="width: 121px" />
      </div>

      <template v-if="companyName">
        <Popover v-model:open="popoverIsOpen">
          <PopoverTrigger as="div">
            <TooltipProvider :delay-duration="0">
              <Tooltip>
                <TooltipTrigger
                  as="div"
                  class="mt-8 flex cursor-pointer items-center justify-between overflow-hidden rounded-[3px] border border-[#E2E8F0] p-2 text-base font-bold text-primary"
                >
                  <div data-test-id="company-name" class="truncate">
                    {{ companyName }}
                  </div>
                  <ph-caret-up-down size="20" class="flex-shrink-0" />
                </TooltipTrigger>
                <TooltipContent align="start">
                  <p>{{ companyName }}</p>
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          </PopoverTrigger>
          <PopoverContent
            as-child
            :side-offset="12"
            class="m-0 w-full max-w-sm overflow-hidden p-0 max-sm:max-w-[320px]"
            align="start"
          >
            <SharedSideBarListOfOrganisations @add-new="handleAddNew" />
          </PopoverContent>
        </Popover>
      </template>

      <div class="flex break-all pt-9 lg:hidden">
        <mail-box />
      </div>

      <div class="flex flex-col pt-9">
        <template v-if="showAppLinks">
          <side-bar-item
            icon-name="layout"
            name="Dashboard"
            class="mb-9"
            route="/dashboard"
            @click="handleCloseSidebar"
          />

          <small v-if="showNavContent" class="mb-4 font-medium text-[#3B4B72]"
            >BILLHUB</small
          >
          <template v-if="showNavContent">
            <side-bar-item
              icon-name="file"
              name="Bills"
              class="mb-3"
              route="/bills"
              @click="handleCloseSidebar"
            />
            <!-- <side-bar-item
              v-if="permissionsNeededHere.canSeePayroll"
              icon-name="payroll"
              name="Payroll"
              class="mb-3"
              route="/payroll"
              @click="handleCloseSidebar"
            /> -->
          </template>

          <template
            v-if="
              isGNPLUser &&
              showNavContent &&
              permissionsNeededHere.canSeeRepaymentsPage
            "
          >
            <side-bar-item
              icon-name="repayments"
              name="Repayments"
              route="/repayments"
              class="mb-3"
              @click="handleCloseSidebar"
          /></template>

          <template v-if="showNavContent">
            <side-bar-item
              icon-name="user"
              name="Vendors"
              route="/vendors"
              @click="handleCloseSidebar"
            />
          </template>

          <div v-if="showNavContent" class="my-3 border-b"></div>
        </template>

        <!-- <side-bar-item
          icon-name="presentation-chart"
          name="Analytics"
          class="mb-3"
          route="/analytics"
        /> -->

        <template v-if="showSettings">
          <Accordion type="single" class="settings-menu" collapsible>
            <AccordionItem value="setting">
              <AccordionTrigger
                class="h-8 rounded-sm px-2 text-[#132248] hover:bg-gray-200 hover:no-underline"
              >
                <div class="flex items-center gap-2">
                  <the-icon icon-name="gear" size="s" /><span
                    class="text-sm font-medium text-[#1A2D5B]"
                    >Settings</span
                  >
                </div>
              </AccordionTrigger>
              <AccordionContent>
                <div class="-mb-4 ml-5">
                  <div class="border-l">
                    <div class="ml-2">
                      <side-bar-item
                        icon-name="bussiness"
                        name="Business"
                        route="/settings/business?active=business-details"
                        @click="handleCloseSidebar"
                      />
                      <side-bar-item
                        icon-name="team-two"
                        name="Team"
                        route="/settings/team"
                        @click="handleCloseSidebar"
                      />
                      <side-bar-item
                        icon-name="bank"
                        name="Connections"
                        route="/settings/connections?active=accounting"
                        @click="handleCloseSidebar"
                      />
                      <side-bar-item
                        icon-name="profile-star"
                        name="Profile"
                        route="/settings/profile"
                        @click="handleCloseSidebar"
                      />
                    </div>
                  </div>
                </div>
              </AccordionContent>
            </AccordionItem>
          </Accordion>
        </template>
      </div>
    </div>
    <div class="bottom-0 mt-auto w-full p-4">
      <div
        v-if="showNavContent"
        class="my-2 flex max-w-max items-center gap-x-2"
        role="button"
        @click="handleOpenPlaylist"
      >
        <TooltipProvider>
          <Tooltip>
            <TooltipTrigger>
              <Button size="icon" class="rounded-full duration-200">
                <the-icon icon-name="video" size="xs" />
              </Button>
            </TooltipTrigger>
            <TooltipContent side="right">
              <div class="text-xs font-bold text-primary">
                Lenkie video resources
              </div>
              <p class="text-grey-500 mt-1 text-xs">
                Stay up-to-date with the latest how-to videos on the app.
              </p>
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
        <div class="text-sm font-medium text-primary">Video resources</div>
      </div>
      <div class="mt-5 border-t pt-5">
        <!-- <Button variant="outline" class="w-full"
              ><the-icon icon-name="logout" style="width: 15px" /><span
                class="pl-2 text-sm font-medium text-primary"
                >Log out</span
              >
              @click="() => navigateTo('/logout')"
            </Button> -->

        <div
          class="flex items-center justify-between rounded-[0.25rem] bg-white p-0"
        >
          <div
            class="flex items-stretch gap-2 p-2 font-semibold text-[#1A2D5B]"
          >
            <p
              class="relative flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full border border-[#000000] border-opacity-10 bg-[#F2F4F7] uppercase"
            >
              {{ initials }}
              <span
                class="absolute bottom-0 right-0 block h-2.5 w-2.5 rounded-full bg-[#17B26A]"
              ></span>
            </p>
            <p class="flex h-10 flex-col justify-between">
              <span
                class="line-clamp-1 font-semibold capitalize"
                data-test-id="user-fullname"
              >
                {{ fullName }}</span
              >
              <span
                v-if="currentRoleName"
                class="text-xs font-medium uppercase"
                data-test-id="user-rolename"
                >{{
                  currentRoleName.toLowerCase() === 'administrator'
                    ? 'admin'
                    : currentRoleName
                }}</span
              >
            </p>
          </div>

          <Popover>
            <PopoverTrigger as="button" class="h-10 cursor-pointer px-1">
              <!-- <button class="h-10 cursor-pointer px-1"> -->
              <IconsEllipsisVertical class="pointer-events-none h-6 w-6" />
              <!-- </button> -->
            </PopoverTrigger>
            <PopoverContent
              align="end"
              class="w-max cursor-pointer p-0"
              :side="'top'"
              :side-offset="16"
            >
              <button
                class="flex h-10 !cursor-pointer items-center gap-2.5 rounded-[0.375rem] bg-white px-4 py-2 text-sm font-medium text-primary hover:bg-slate-100"
                @click="logout()"
              >
                <LogOutIcon class="h-4 w-4" /> <span>Log out</span>
              </button>
            </PopoverContent>
          </Popover>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { XIcon, LogOutIcon } from 'lucide-vue-next'
import { PhCaretUpDown } from '@phosphor-icons/vue'
import SideBarItem from './side-bar-item.vue'
import MailBox from '@/components/shared/mail-box.vue'
import TheIcon from '@/components/shared/the-icon.vue'
import { Button } from '@/components/ui/button'
import { useProfileStore } from '@/stores/profile'
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@/components/ui/tooltip'

import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/ui/popover'
import { logout } from '~/lib/utils'

interface Props {
  showAppLinks?: boolean
  showSettings?: boolean
}

withDefaults(defineProps<Props>(), {
  showAppLinks: true,
  showSettings: true,
})

const { $listen } = useNuxtApp()
const profileStore = useProfileStore()
const { profile, organisationId } = storeToRefs(profileStore)

const featureFlagStore = useFeatureFlagStore()
const { hasOnboardingAcknowledged } = storeToRefs(featureFlagStore)

const fullName = computed(() => {
  if (!profile.value) return 'N/A'

  const { given_name, family_name } = profile.value
  return `${given_name} ${family_name}`
})

const initials = computed(() => {
  if (!profile.value) return 'N/A'

  const { given_name, family_name } = profile.value
  return `${given_name[0]}${family_name[0]}`
})

const openSidebar = ref(false)

const showNavContent = computed(
  () => !!organisationId.value && hasOnboardingAcknowledged.value === true,
)
const isGNPLUser = computed(() => profileStore?.isGNPLUser)
const companyName = computed(
  () => profileStore?.data.organisation?.trading_name,
)

$listen('open:sidebar', ({ trigger }) => {
  if (trigger) {
    openSidebar.value = true
  }
})

const handleCloseSidebar = () => {
  openSidebar.value = false
}

const handleOpenPlaylist = () => {
  const lenkieYoutubePlaylistUrl =
    'https://www.youtube.com/playlist?list=PLpNJ6ZGC0KRqCTh87tcLMA60u6guDPPZn'
  window.open(lenkieYoutubePlaylistUrl, '_newtab')
}

const { currentPermissionsIds, currentRoleName } = useUserRole()

const permissionsNeededHere = computed(() => {
  const permissionsSet = new Set(currentPermissionsIds.value)

  return {
    canSeeRepaymentsPage:
      permissionsSet.has('CreditFacility.ViewCreditFacilityRepayments') &&
      permissionsSet.has('CreditFacility.ExportCreditFacilityRepayments'),
    canSeePayroll: permissionsSet.has('Payroll'),
  }
})

const popoverIsOpen = ref(false)

const router = useRouter()

function handleAddNew() {
  router.push('/organisation/create')
  popoverIsOpen.value = false
}
</script>

<style scoped>
.settings-menu > div {
  border: none !important;
}
</style>
